.module-Navigation
{

    ul {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      list-style-type: none;
    }

    ul li a {
      position: relative;
      display: block;
      text-decoration: none;
      font-size: 1rem;;
      color: var(--nav_text_color) !important;
      text-transform: uppercase;
      padding: 1em 0;
      transition: 0.3s;
    }
    ul li a::after {
      position: absolute;
      content: "";
      width: 100%;
      height: 2px;
      top: 100%;
      left: 0;
      background: var(--theme_color);
      transition: transform 0.3s;
      transform: scaleX(0);
      transform-origin: right;
    }
    ul li a:hover,
    ul li a.active {
      color: var(--theme_color);
    }
    ul li a.active::after,
    ul li a:hover::after {
      transform: scaleX(1);
      transform-origin: left;
    }
    .navbar-text,
    .navbar-text button
    {
        color: var(--nav_text_color) !important;
    }
    .container-fluid
    {
        background-color: grey;
    }
}
